:root {
  //--main-color: #fcc500; antiga
  --main-color: #e5ad00; // nova cor
  --main-color-background: #f8f9fa;
  --main-color-background-2: #f2f2f2;
  --main-color-border: #ededed;
  --main-color-border-2: #ced4da;
  --main-color-black: #252a33;
  --main-color-error: #e24c4c;
  --main-color-white: #fff;
  --main-color-p: #6c757d;
  --main-color-label: #6c757d;
  --gray-primary-color: #333;
  --gray-secondary-color: #666;
  --primary-bkg: --gray-primary-color;
  --danger-bkg: #dd3e44;

  .color-warning {
    color: var(--orange-500);
  }
}
