.mobile-visible {
  display: none !important;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

.strong {
  font-weight: 600;
}

mark,
.mark {
  padding: 3.2px;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75rem;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}

a:hover {
  color: #024dbc;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875rem;
  -ms-overflow-style: scrollbar;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875rem;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--main-color-label);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
}

input,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  overflow: visible;
}

select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 8px;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  white-space: normal;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

[type="search"] {
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 8px;
}

.initialism {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 10px;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  color: var(--main-color-label);
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 4px;
  background-color: var(--main-color-white);
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 5px;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: var(--main-color-label);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.875rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1400px;
  }
}

.row {
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 5px;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 5px;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 10px;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 10px;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 15px;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 15px;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 20px;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 20px;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 25px;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 25px;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 30px;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 30px;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 35px;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 35px;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 40px;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 40px;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 45px;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 45px;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 50px;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 50px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 5px;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 5px;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 10px;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 10px;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 15px;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 15px;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 20px;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 20px;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 25px;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 25px;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 30px;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 30px;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 35px;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 35px;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 40px;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 40px;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 45px;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 45px;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 50px;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 50px;
  }
}

.form-label {
  margin-bottom: 8px;
}

.col-form-label {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 4px;
  font-size: 0.875rem;
  color: var(--main-color-label);
}

.form-control {
  display: block;
  width: 100%;
  min-height: 30px;
  padding: 0 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  background-color: var(--main-color-white);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  content: "";
  border-top: 4.8px solid;
  border-right: 4.8px solid transparent;
  border-bottom: 0;
  border-left: 4.8px solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 160px;
  padding: 8px 0;
  margin: 2px 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: var(--main-color-white);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 2px;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  content: "";
  border-top: 0;
  border-right: 4.8px solid transparent;
  border-bottom: 4.8px solid;
  border-left: 4.8px solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 2px;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  content: "";
  border-top: 4.8px solid transparent;
  border-right: 0;
  border-bottom: 4.8px solid transparent;
  border-left: 4.8px solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 2px;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 4.08px;
  vertical-align: 4.08px;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 4.08px;
  vertical-align: 4.08px;
  content: "";
  border-top: 4.8px solid transparent;
  border-right: 4.8px solid;
  border-bottom: 4.8px solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 5px 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 2.5px 10px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  background-color: var(--main-color-background);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--main-color-white);
  text-decoration: none;
  background-color: #0d6efd;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--main-color-label);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 8px 10px;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--main-color-label);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 2.5px 10px;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: var(--main-color-white);
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: var(--main-color-white);
  background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.dropdown-toggle-split {
  padding-right: 9px;
  padding-left: 9px;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: var(--main-color-label);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: var(--main-color-label);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: var(--main-color-white);
  border-color: #dee2e6 #dee2e6 var(--main-color-white);
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 4px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--main-color-white);
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 16px;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 8px;
  padding-bottom: 8px;
}

.navbar-collapse {
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 4px 12px;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 4px;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 8px;
    padding-left: 8px;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 8px;
  padding-left: 8px;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: var(--main-color-white);
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: var(--main-color-white);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--main-color-white);
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: var(--main-color-white);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--main-color-white);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 10px 10px;
}

.card-title {
  margin-bottom: 5px;
}

.card-subtitle {
  margin-top: -2.5px;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 10px;
}

.card-header {
  padding: 5px 10px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 3px 3px 0 0;
}

.card-footer {
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 3px 3px;
}

.card-header-tabs {
  margin-right: -5px;
  margin-bottom: -5px;
  margin-left: -5px;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -5px;
  margin-left: -5px;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  border-radius: 3px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-group > .card {
  margin-bottom: 0.9375rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: var(--main-color-white);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 16px;
  height: 8px;
  margin: 0 4.8px;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 8px;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[x-placement^="top"] > .popover-arrow {
  bottom: -9px;
}

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 8px 8px 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 8px 8px 0;
  border-top-color: var(--main-color-white);
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 8px;
}

.bs-popover-right > .popover-arrow,
.bs-popover-auto[x-placement^="right"] > .popover-arrow {
  left: -9px;
  width: 8px;
  height: 16px;
  margin: 4.8px 0;
}

.bs-popover-right > .popover-arrow::before,
.bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 8px 8px 8px 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .popover-arrow::after,
.bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 8px 8px 8px 0;
  border-right-color: var(--main-color-white);
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 8px;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
  top: -9px;
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 8px 8px 8px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 8px 8px 8px;
  border-bottom-color: var(--main-color-white);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 16px;
  margin-left: -8px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 8px;
}

.bs-popover-left > .popover-arrow,
.bs-popover-auto[x-placement^="left"] > .popover-arrow {
  right: -9px;
  width: 8px;
  height: 16px;
  margin: 4.8px 0;
}

.bs-popover-left > .popover-arrow::before,
.bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 8px 0 8px 8px;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .popover-arrow::after,
.bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 8px 0 8px 8px;
  border-left-color: var(--main-color-white);
}

.popover-header {
  padding: 8px 10px;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 3.8px;
  border-top-right-radius: 3.8px;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 10px 10px;
  color: #212529;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #050505 !important;
}

.bg-light {
  background-color: var(--main-color-background) !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

.bg-body {
  background-color: var(--main-color-white) !important;
}

.bg-white {
  background-color: var(--main-color-white) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

/*Base CSS*/
/*****************************
Base - Default
*****************************/
/*-- Google Font --*/
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.75;
  position: relative;
  visibility: visible;
  color: #000;
}

body[dir="rtl"] {
  text-align: right;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Poppins", sans-serif;
  color: var(--gray-primary-color);
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: var(--gray-secondary-color);
  text-decoration: none;
  transition: all 0.5s ease 0s;
  outline: none;
}

a:hover {
  color: var(--main-color);
}

.icon-text-left i {
  padding-left: 7px;
}

.icon-text-right i {
  padding-right: 7px;
}

/*Custom Border CSS*/
.border-around {
  border: 2px solid #eee;
}

.border-left-none {
  border-left: transparent;
}

.border-right-none {
  border-right: transparent;
}

.border-top-none {
  border-top: transparent;
}

.border-bottom-none {
  border-bottom: transparent;
}

/* Section CSS*/
.section-top-gap-50 {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .section-top-gap-50 {
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .section-top-gap-50 {
    margin-top: 40px;
  }
}

.section-inner-bg {
  background: var(--main-color-background);
  padding-top: 25px;
  padding-bottom: 25px;
}

.bg-main {
  background: var(--main-color-background);
}

@media (min-width: 768px) {
  .section-inner-bg {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .section-inner-bg {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section-content-gap {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.625rem;
  font-weight: 700;
  position: relative;
  margin-top: -7px;
}

@media (min-width: 768px) {
  .section-title {
    font-size: 1.625rem;
  }
}

.section-title::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 2px;
  background: var(--main-color);
  left: 0;
  bottom: -10px;
}

.facebook {
  background: #4867aa;
}

.twitter {
  background: #1da1f2;
}

.pinterest {
  background: #bd081b;
}

.google-plus {
  background: #dd5144;
}

.linkedin {
  background: #007bb6;
}

@keyframes wave-animate {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    transform: scale(1.6);
    opacity: 0.5;
  }
}

.sort-layout-single > .row > .col-12 {
  margin-top: 30px;
}

/*****************************
Components - Sidebar Widget
*****************************/
.siderbar-section {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .siderbar-section {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .siderbar-section {
    margin-top: 0;
  }
}

.sidebar-single-widget {
  margin-top: 20px;
  margin-bottom: 40px;
  padding-bottom: 37px;
  border-bottom: 2px solid var(--main-color-background);
}

.sidebar-single-widget:first-child {
  margin-top: 0px;
}

.sidebar-single-widget:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sidebar-title {
  text-transform: capitalize;
  margin-bottom: 30px;
  font-weight: 600;
}

.sidebar-banner {
  margin: 0 auto;
  display: block;
}

.filter-type-price {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.filter-type-price label {
  display: inline-block;
}
.ui-slider-horizontal {
  height: 5px;
}

.ui-widget-header {
  background: var(--main-color);
}

.ui-slider-horizontal .ui-slider-handle {
  top: -8px;
  margin-left: -0.6em;
}

.ui-slider .ui-slider-handle {
  border-radius: 50%;
  outline: none;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 5px solid var(--main-color);
  background: #f6f6f6;
  font-weight: normal;
  color: #454545;
  border-radius: 50%;
  outline: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 5px solid var(--main-color);
  background: #f6f6f6;
  font-weight: normal;
  color: #454545;
  border-radius: 50%;
  outline: none;
}

.filter-type-select {
  margin-top: -15px;
}

.tag-link {
  margin-bottom: -10px;
}

.tag-link a {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #eee;
  margin-bottom: 10px;
  margin-left: 5px;
  border-radius: 3px;
}

.tag-link a:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: var(--main-color-white);
}

.recent-post-list {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid #eee;
}

.recent-post-list:first-child {
  margin-top: 0;
}

.recent-post-list:last-child {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.post-image {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  overflow: hidden;
}

.post-image img {
  width: 100%;
}

.post-link {
  display: block;
  margin-bottom: 5px;
  font-size: 0.875rem;
}

.post-date {
  font-size: 0.8125rem;
}

/*****************************
Components - Page Pagination
*****************************/
.page-pagination {
  margin-top: 42px;
}

.page-pagination li {
  display: inline-block;
  margin-right: 15px;
}

.page-pagination li:last-child {
  margin-right: 0;
}

.page-pagination li a {
  padding: 5px 10px;
  display: block;
  background: #f1f1f1;
  border-radius: 3px;
  font-weight: 500;
}

.page-pagination li a:hover,
.page-pagination li a.active {
  background: var(--main-color);
  color: var(--main-color-white);
}

/*****************************
Components - Comment
*****************************/
.comment-list {
  border-bottom: 1px solid #eee;
  padding-top: 20px;
  padding-bottom: 15px;
}

.comment-list:first-child {
  padding-top: 0;
}

.comment-list:last-child {
  border: none;
  padding-bottom: 0;
}

.comment-list > .comment-reply {
  margin-left: 0;
}

@media (min-width: 768px) {
  .comment-list > .comment-reply {
    margin-left: 100px;
  }
}

.comment-list > .comment-reply li {
  border-top: 1px solid #eee;
  padding-top: 23px;
  margin-top: 0px;
}

.comment-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media (min-width: 576px) {
  .comment-wrapper {
    flex-direction: row;
  }
}

.comment-img {
  margin-right: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.comment-img img {
  width: 78px;
  height: 78px;
  border-radius: 5px;
}

.comment-content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.comment-name {
  font-weight: 600;
  margin-right: 10px;
}

.comment-content-right a i {
  padding-right: 10px;
}

/*****************************
Components - Testimonial
*****************************/
.testimonial-section {
  text-align: center;
}

.testimonial-title {
  margin-bottom: 20px;
}

.testimonial-slider-single p {
  padding: 0 0;
  line-height: 26px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .testimonial-slider-single p {
    padding: 0 2%;
  }
}

@media (min-width: 992px) {
  .testimonial-slider-single p {
    padding: 0 8%;
  }
}

@media (min-width: 1200px) {
  .testimonial-slider-single p {
    padding: 0 12%;
  }
}

.testimonial-slider-single .testimonial-img {
  margin: 0 auto;
  width: 100px;
  height: 100px;
}

.testimonial-slider-single .testimonial-img img {
  width: 100%;
  display: inline-block;
  border-radius: 50%;
}

.testimonial-slider-single .name {
  display: block;
  font-weight: 700;
  margin-top: 12px;
}

.testimonial-slider-single .job-title {
  display: block;
  margin-top: 18px;
  margin-bottom: 18px;
}

/*****************************
Components - Tab Style
*****************************/
/* Tab Style CSS */
.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

.sort-tab-btn,
.product-tab-btn,
.product-details-content-tab-btn {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .sort-tab-btn,
  .product-tab-btn,
  .product-details-content-tab-btn {
    margin-top: 0;
  }
}

.sort-tab-btn > li,
.product-tab-btn > li,
.product-details-content-tab-btn > li {
  margin-right: 40px;
}

.sort-tab-btn > li:last-child,
.product-tab-btn > li:last-child,
.product-details-content-tab-btn > li:last-child {
  margin-right: 0;
}

.sort-tab-btn > li > .nav-link,
.product-tab-btn > li > .nav-link,
.product-details-content-tab-btn > li > .nav-link {
  color: var(--gray-secondary-color) !important;
  transition: all 0.5s ease 0s;
  font-weight: 500;
  padding: 0;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .sort-tab-btn > li > .nav-link,
  .product-tab-btn > li > .nav-link,
  .product-details-content-tab-btn > li > .nav-link {
    margin-top: 0;
  }
}

.sort-tab-btn > li > .nav-link:hover,
.sort-tab-btn > li > .nav-link.active,
.product-tab-btn > li > .nav-link:hover,
.product-tab-btn > li > .nav-link.active,
.product-details-content-tab-btn > li > .nav-link:hover,
.product-details-content-tab-btn > li > .nav-link.active {
  color: var(--main-color) !important;
  transition: all 0.5s ease 0s;
}

.sort-tab-btn > li > .nav-link:hover img,
.sort-tab-btn > li > .nav-link.active img,
.product-tab-btn > li > .nav-link:hover img,
.product-tab-btn > li > .nav-link.active img,
.product-details-content-tab-btn > li > .nav-link:hover img,
.product-details-content-tab-btn > li > .nav-link.active img {
  transition: all 0.5s ease 0s;
  filter: invert(100%);
}

.product-details-content-tab-btn {
  margin-top: 0;
}

.product-details-content-tab-btn > li {
  margin-right: 0;
}

.product-details-content-tab-btn > li a {
  margin-right: 40px;
}

.product-details-content-tab-btn > li > .nav-link h5,
.product-details-content-tab-btn > li > .nav-link .h5 {
  color: var(--gray-primary-color) !important;
  transition: all 0.5s ease 0s;
  font-weight: 600;
  padding: 0;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .product-details-content-tab-btn > li > .nav-link h5,
  .product-details-content-tab-btn > li > .nav-link .h5 {
    margin-top: 0;
  }
}

.product-details-content-tab-btn > li > .nav-link:hover h5,
.product-details-content-tab-btn > li > .nav-link:hover .h5,
.product-details-content-tab-btn > li > .nav-link.active h5,
.product-details-content-tab-btn > li > .nav-link.active .h5 {
  color: var(--main-color) !important;
  transition: all 0.5s ease 0s;
}

.product-details-content-tab-btn > li > .nav-link:hover h5 img,
.product-details-content-tab-btn > li > .nav-link:hover .h5 img,
.product-details-content-tab-btn > li > .nav-link.active h5 img,
.product-details-content-tab-btn > li > .nav-link.active .h5 img {
  transition: all 0.5s ease 0s;
  filter: invert(100%);
}

.sort-tab-btn > li {
  margin-right: 20px;
}

.sort-tab-btn > li:last-child {
  margin-right: 0;
}

.product-tab-btn {
  margin-top: 35px;
  margin-bottom: -20px;
  font-size: 1rem;
}

.product-tab-btn > li > .nav-link {
  margin-top: 0;
}

@media (min-width: 768px) {
  .product-tab-btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sort-tab-btn {
  margin-top: 0px;
}

.sort-tab-btn > li > .nav-link {
  margin-top: 0;
}

.sort-box > * {
  margin-bottom: 20px;
}

.sort-box > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .sort-box > * {
    margin-bottom: 0;
  }
}

/*****************************
Components - Slider Style
*****************************/
/*Slick Slider Modifier CSS*/
.slick-slide {
  outline: none;
}

/* Slick Slider -  Hero Slider */
.hero-slider-dots {
  position: relative;
}

.hero-slider-dots .slick-dots {
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
}

.hero-slider-dots .slick-dots li {
  margin-right: 10px;
}

.hero-slider-dots .slick-dots li:last-child {
  margin-right: 0;
}

/* Slick Slider -  Default Slider */
.product-default-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.product-default-slider .slick-list .slick-slide {
  margin-left: 15px;
  margin-right: 15px;
  overflow-x: hidden;
}

@media (min-width: 576px) {
  .product-default-slider .slick-list .slick-slide {
    overflow-x: visible;
  }
}

.default-slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  border-radius: 50%;
  color: var(--main-color);
  z-index: 9;
  font-size: 44px;
  background: transparent;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease 0s;
}

.product-default-slider {
  position: relative;
}

.default-slider-arrow--left {
  left: calc(0% + 15px);
}

.default-slider-arrow--right {
  right: calc(0% + 15px);
}

.product-default-slider:hover .default-slider-arrow {
  visibility: visible;
  opacity: 1;
}

.product-default-slider:hover .default-slider-arrow--left {
  left: -30px;
}

.product-default-slider:hover .default-slider-arrow--right {
  right: -30px;
}

/*Testimonial SLider*/
.testimonial-slider-dots {
  position: relative;
}

.testimonial-slider-dots .slick-dots {
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
}

.testimonial-slider-dots .slick-dots li {
  margin-right: 10px;
}

.testimonial-slider-dots .slick-dots li:last-child {
  margin-right: 0;
}

.form-submit-btn {
  border: none;
  background: var(--gray-primary-color);
  color: var(--main-color-white);
  text-transform: capitalize;
  font-weight: 500;
  padding: 10px 20px;
  display: block;
  transition: 0.3s;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
}

.form-submit-btn:hover {
  background: var(--main-color);
}

/*****************************
Pages - Blogs
*****************************/
.blog-grid-wrapper,
.blog-full-width-wrapper {
  margin-bottom: -40px;
}

.blog-grid-wrapper .col-12,
.blog-full-width-wrapper .col-12 {
  margin-bottom: 40px;
}

.blog-image-link {
  overflow: hidden;
}

.blog-image-link img {
  width: 100%;
  height: auto;
}

.blog-image-slider {
  overflow: hidden;
  height: auto;
}

.blog-image-slider img {
  width: 100%;
  height: 290px;
  object-fit: cover;
}

.blog-image-slider:hover .gallery-nav {
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

.blog-image-video {
  position: relative;
  overflow: hidden;
}

.blog-image-video img {
  height: 290px;
  object-fit: cover;
}

.blog-image-video::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
}

.video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.375rem;
  color: white;
  z-index: 9;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--main-color);
  text-align: center;
  line-height: 50px;
}

.video-play-btn:hover {
  color: var(--main-color-white);
}

.blog-single-wrapper .post-title {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 25px;
}

.blog-single-img {
  margin-bottom: 40px;
}

.blog-single-img img {
  height: 440px;
  object-fit: cover;
  width: 100%;
}

.blockquote-content {
  padding: 15px 30px;
  background: #e9ecef;
  border-left: 2px solid var(--main-color);
  font-style: italic;
  font-size: 0.875rem;
}

.para-tags {
  border: 1px solid #eee;
  padding: 10px 20px;
  border-radius: 3px;
  display: flex;
  margin: 40px 0;
}

.para-tags span {
  font-weight: 600;
}

.para-tags ul {
  padding-left: 10px;
}

.para-tags li {
  display: inline-block;
  margin-right: 5px;
}

.para-tags li::after {
  content: ",";
  padding-left: 2px;
}

.para-tags li:last-child::after {
  content: "";
}

/*****************************
Pages - About Us
*****************************/
/* About Us Top Area */
.about-us-top-content {
  margin-bottom: -12px;
}

.about-us-top-content h4,
.about-us-top-content .h4 {
  margin-top: 27px;
  margin-bottom: 15px;
}

.about-us-top-content p {
  padding: 0 0;
  line-height: 26px;
  margin-bottom: 20px;
}

.about-us-top-content p span {
  font-weight: 600;
}

@media (min-width: 768px) {
  .about-us-top-content p {
    padding: 0 2%;
  }
}

@media (min-width: 992px) {
  .about-us-top-content p {
    padding: 0 8%;
  }
}

@media (min-width: 1200px) {
  .about-us-top-content p {
    padding: 0 12%;
  }
}

/* About Us Center Area */
.about-us-center-area,
.about-us-bottom-area {
  margin-bottom: -37px;
}

.about-us-center-content h4,
.about-us-center-content .h4 {
  margin-bottom: 15px;
}

.about-promo-single-item {
  text-align: center;
  margin-bottom: 30px;
}

.about-promo-single-item img {
  width: 90px;
  height: 90px;
}

.about-promo-single-item h6,
.about-promo-single-item .h6 {
  font-weight: 700;
  font-size: 1.125rem;
  margin-top: 20px;
  margin-bottom: 15px;
}

.about-feature-single-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid #eee;
  min-height: 100px;
  margin-bottom: 10px;
}

.about-feature-single-item img {
  width: 35px;
}

.about-feature-single-item h6,
.about-feature-single-item .h6 {
  font-weight: 700;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 15px;
}

/*****************************
CATALOG
*****************************/

.app-catalog-component article {
  width: 100% !important;
}

.product-details .details .form-controls {
  margin-bottom: 20px;
}

.contact-section .msg {
  background-color: var(--main-color-background);
}

.map-section {
  margin-top: 60px;
}

@media (max-width: 575.98px) {
  .overflow-hidden {
    overflow: hidden;
  }

  .mobile-visible {
    display: block !important;
  }

  .mobile-invisible {
    display: none !important;
  }

  .hero-content h5 {
    font-size: 1rem;
  }

  .hero-content h2 {
    font-size: 1.25rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-content p {
    line-height: 25px;
  }

  .hero-slider-dots .slick-dots {
    bottom: 10px;
  }

  .component-search-trigger {
    display: none;
  }

  .component-search-trigger .header-search-box .default-search-style-input-btn {
    padding: 0 15px;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .component-search-trigger .header-search-box .default-search-style-input-box {
    padding: 5px 80px 5px 10px;
  }

  .nav-trigger-alert {
    color: var(--gray-secondary-color) !important;
  }

  .nav-trigger-alert:hover {
    color: var(--main-color) !important;
  }

  .contact-details-wrapper {
    padding: 0;
  }

  .privacy-policy-wrapper summary {
    font-size: 0.875rem;
  }

  .privacy-section {
    margin: 0 20px;
  }

  .about-us-bottom-area {
    margin: 0 20px;
  }

  .about-us-top-area {
    margin: 0 20px;
  }

  .app-catalog-component .btn-sub-categories {
    line-height: 10px;
  }

  .offcanvas {
    width: 315px;
    height: 90%;
  }

  .mobile-header-section {
    background: var(--main-color-white);
  }

  .footer-bottom-catalog {
    padding-bottom: 60px;
  }

  .contact-form {
    padding: 10px;
  }

  .contact-form-info {
    padding: 30px;
  }

  .contact-form-info a {
    font-size: 0.75rem;
  }

  .contact-form-info span {
    font-size: 0.75rem;
  }

  .map-section {
    margin: 0;
  }
}
